import React, { useEffect } from 'react';
import '../../App.css';
import GovtHero from '../GovtHero';
import GovtB from '../GovtB';
import GovtC from '../GovtC';
import GovtD from '../GovtD';
import AboutUsC from '../AboutUsC';
import GrantAbout from '../GrantAbout';
import FooterB from '../FooterB';

function Govt() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);
  return (
    <>
    <GovtHero /> 
    <GovtB />
    <GovtC />
    <GovtD />
    <GrantAbout />
    <AboutUsC />
    <FooterB />
    </>
  );
}

export default Govt;