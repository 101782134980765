import React from 'react'
import '../App.css';
import './HomeF.css';
import imgHome from './fr8relay-home-2.png';
import imgHome2 from '../fr8relay logo.png'

function HomeF() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1',width: '100%',objectFit:'contain'}}>
    <div className='homeF-container'>
   
    <img src={imgHome} alt="Home Image"  />
    <div className="title-background" ></div>

    <div className='text-wrapperF' >
      
        <img src={imgHome2}/>
        <div className="content-wrapperF">
            <h3 >Transforming Long Haul Trucking</h3>
            <p >
            FR8relay is an Arkansas-based technology company that envisions a more efficient, profitable, equitable, and sustainable trucking logistics ecosystem. FR8relay is tackling the biggest concerns in the trucking industry. Over 70% of freight in the United States moves by truck, but core inefficiencies in the way long-haul trucking operates threaten the industry’s sustainability and profitability.
            </p>
            <div className="learn-moreF">
                <p>Learn more <i className="fas fa-arrow-right"></i></p>
            </div>
        </div>
    </div>
</div>
</div>



  )
}

export default HomeF;