import React from 'react'
import './Team-hero.css';
import { Button } from './Button';
import '../App.css';
import imgHome from '../fr8relay-home.jpeg';

function TeamHero() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'0'}}>
    <div className='team hero-container'>
    <video src={imgHome} alt="Kavya AI" /> 
    <h1>Want to Extend your <span className='white-text'>In-house</span> development capabilities</h1>
  <p>We offer strategic, long-term partnership to companies with an outsourcing need to augment their in-house development capabilities.
</p>

    <div className='team hero-btns'>
        <Button className='team btns' buttonStyle='btn--outline' buttonSize='btn--large'>
            Contact Us
        </Button>
    </div>
</div>
</div>
  );
}

export default TeamHero;