import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSectionSol.css';
import imgHome from '../ML-hero.png';
import imgHome2 from "../AI-home-j.jpeg"

function HeroSectionSol() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='hero-container-sol'>
      <div className='hero-image'>
        <img src={imgHome2} alt='Software' className='image-sol' />
        </div>
      <h1 >Unleashing Power of AI</h1>
      <h2 >and Machine Learning</h2>
      <p >Explore the future of technology with our cutting-edge AI offerings designed to propel your business into a new era of innovation and efficiency.</p>
      <div className='hero-btns-sol' >
        <Button className='btns-sol' buttonStyle='btn--outline' buttonSize='btn--large'>
          Contact Us
        </Button>
      </div>
      
    </div>
    </div>
  );
}

export default HeroSectionSol;
