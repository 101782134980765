import React from 'react'
import './TeamCards.css';
import '../App.css';
import TeamCardsItem from './TeamCardsItem';
import imgHome from '../team1.jpeg';
import imgHome2 from '../team-conf.png';
import imgHome3 from '../team3.jpeg';

function TeamCards() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='teams-cards2'> {/* Updated class name */}
    <h2 className='teams-cards2__title'>How we can help build your Dedicated Teams?</h2> {/* Updated class name */}
    <div className='teams-cards2__container'> {/* Updated class name */}
        <div className='teams-cards2__items'>
            <TeamCardsItem
                imageSrc={imgHome}
                text='1. Assessment of Client Needs'
                additionalText='It begins by thoroughly understanding the technical requirements and objectives of the client organization. This involves engaging in open communication with key stakeholders to identify their specific needs, challenges, and desired outcomes. Gather information about the scope of technical projects, required skill sets, budget constraints, and timeline expectations.'
            />
            <TeamCardsItem
                imageSrc={imgHome2}
                text='2. Team Formation and Skill Matching'
                additionalText='Based on the assessment, assemble a technical team with the necessary expertise and experience, focusing on recruiting individuals with relevant technical skills, domain knowledge, and project management capabilities. Ensure team members have strong communication skills and the ability to collaborate effectively with stakeholders.'

            />
            <TeamCardsItem
                imageSrc={imgHome3}
                text='3. Training and Collaboration Setup'
                additionalText='Once the team is formed, provide necessary training and resources to ensure that team members are equipped to deliver high-quality technical solutions. Regular progress updates, and feedback mechanism is established to promote continuous improvement and ensure that the technical team operates cohesively to deliver successful outcomes for the client organization.'
    
            />
          
        </div>
    </div>
</div>
</div>
  )
}

export default TeamCards