import React from 'react'
import './GovtD.css';
import { Button } from './Button';
import imgHome from '../govt-ai.jpeg';

function GovtD() {
  return (
    <div style={{backgroundColor:'#555555'}}>
    <div className='GovtD-container'>

    <div className='text-wrapper-GovtD'>
        <img src={imgHome} alt="Home Image" style={{ float: 'left', marginRight: '8%',marginTop: '0%' }} />
        <h1>Our Services</h1>
        <p style={{ color: 'white' }}>
        As a Dedicated Development Team provider we act as a strategic partner for our client's, helping them access skilled resources, manage the development process, and ensure the successful execution of software development or IT projects. Our goal is to create a seamless and efficient collaboration that delivers value to the client's business.</p>
        <p style={{ color: '#e34b4b', fontSize: '1rem' , fontWeight:'bold',marginTop:'2%' }}>  <a href="./OurSolutions" style={{ color: '#e34b4b', fontSize: '16px' , fontWeight:'bold'}}>See our catalog of services</a> </p>
    </div>
</div>
</div>

  );
}

export default GovtD;