import React, { useState, useEffect } from 'react';
import '../App'
import './HeroSectionAbout.css'
import { Button } from './Button';
import img1 from '../About Us.jpeg';
import img2 from '../our-vision.jpeg';
import img3 from '../about-us-hero3.jpeg'


function HeroSectionAbout() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [
        { src: img1, heading: 'Our Vision', text: 'At Connect Dynamics, we aspire to revolutionize the travel industry by seamlessly integrating AI and ML technologies. Our vision is a future where every journey is characterized by efficiency, reliability, and passenger satisfaction, ultimately transforming travel into an effortless and enjoyable experience.' },
        { src: img2, heading: 'Our Story', text: 'Our journey started with a shared passion for using technology to solve travel challenges. Through relentless innovation, we have developed AI and ML algorithms that predict delays accurately and optimize routes for seamless journeys. Today, we lead the way towards a future where travel is an enjoyable experience, not just a means to an end.' },
        { src: img3, heading: 'Our Solution', text: 'Leveraging state-of-the-art AI and ML algorithms, our company specializes in travel delay prediction and route optimization. By analyzing diverse data sets including historical patterns and real-time updates, we provide accurate forecasts and personalized travel solutions, ensuring travelers reach their destinations efficiently and stress-free.' }
    ]; // Array of images with headings and text
    const intervalDuration = 10000; // Interval duration in milliseconds

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Move to the next image index
            setCurrentImageIndex(prevIndex =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, intervalDuration);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [images.length]);

    const handleDotClick = dotIndex => {
        setCurrentImageIndex(dotIndex);
    };

    return (
        <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
        <div className='hero-container2'>
            {/* Scrollable images */}
            <div className='scrollable-images'>
                {images.map((image, index) => (
                    <div key={index} className={`image-container ${index === currentImageIndex ? 'active' : ''}`}>
                        <img src={image.src} alt={`Image ${index}`} className="image" />
                        <div className="text-container">
                            <h1>{image.heading}</h1>
                            <p>{image.text}</p>
                        </div>
                        <div className="scroll-dots-container">
                            {images.map((_, dotIndex) => (
                                <div
                                key={dotIndex}
                                className={`scroll-dot ${dotIndex === currentImageIndex ? 'active' : ''}`}
                                onClick={() => handleDotClick(dotIndex)}
                                style={{ cursor: 'pointer' }}
                                ></div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className='hero-btns2'>
                <Button className='btns' buttonStyle='btn--outline2' buttonSize='btn--large'>
                    <i className="fas fa-comment-alt"></i>
                </Button>
            </div>
        </div>
        </div>
    );
}

export default HeroSectionAbout;