import React, { useState } from 'react';
import './FooterB.css';
import { Link } from 'react-router-dom';
import logo from '../Connc logo white.svg';

function Footer() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        setFormData({
          firstName: '',
          lastName: '',
          email: ''
        });
        alert('Subscription successful!');
      } else {
        alert('Subscription failed. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Subscription failed. Please try again later.');
    }
  };

  return (
    <div className='footer-container-B'>
      <div className='footer-links-B'>
        <div className='footer-link-wrapper-B'>
          <div className='footer-link-items-B'>
            <div style={{ textAlign: 'center' }}>
              <img src={logo} alt="Connect Dynamics Logo" className="footer-logo" />
              <h2 className="footer-heading">
                CONNECT <span> DYNAMICS</span>
              </h2>
            </div>
            <Link to='/' className="footer-follow-us">Follow Us</Link>
            <Link
              className='social-icon-link-B twitter footer-social-icons'
              to='https://www.linkedin.com/company/fr8relay/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
              <i className='fab fa-facebook-f' />
              <i className='fab fa-instagram' />
              <i className='fab fa-twitter' />
            </Link>
          </div>
        </div>
        <div className='footer-link-wrapper-B'>
          <div className='footer-link-items-B footer-contact'>
            <h2>GET IN TOUCH</h2>
            <Link to='/' className="footer-address">
              <i className='fas fa-map-marker-alt' />  900 SE 5th Street, Suite 22F, Bentonville AR 72712
            </Link>
            <Link to='/' className="footer-phone">
              <i className='fas fa-phone' />  +1 479 310 5053
            </Link>
            <Link to='/' className="footer-email">
              <i className='fas fa-envelope' />  hello@ConnectDynamics.org
            </Link>
          </div>
        </div>
        <div className='footer-link-wrapper-B'>
          <div className='footer-link-items-B'>
            <h2 className="footer-links">LINKS</h2>
            <Link to='/About' style={{ marginBottom:'1rem' }}>About Us</Link>
            <Link to='/OurSolutions' style={{ marginBottom:'1rem' }}>AI and Machine Learning</Link>
            <Link to='/Relay' style={{ marginBottom:'1rem' }}>Fr8relay</Link>
            <Link to='/Govt' style={{ marginBottom:'1rem' }}>Government</Link>
          </div>
        </div>
      </div>
      <div className='horizontal-line'></div>
      <section className='social-media-B'>
        <div className='social-media-wrap-B'>
          <small className='website-rights-B'>Connect Dynamics © 2024</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
