import React from 'react'
import '../App.css';
import './OurSolutionsD.css';
import imgHome from '../sol-handshake.jpeg';
import { Button } from './Button';
function OurSolutionsD() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
<div className='OurSolutionsD-container'>
    <div className='text-wrapper-sol-d'> {/* sol */}
        <img src={imgHome} alt="Home Image" style={{ float: 'right', marginRight: '0px' }} />
        <h1>Optimized Solutions for Multimodal Transportation Challenges</h1>
        <p style={{ color: 'white',marginTop:'0%' }}>
        At Connect Dynamics, we understand the intricacies of transportation challenges. That's why our AI and ML solutions are meticulously designed to tackle the nuances of route optimization and travel delay prediction across air, railway, road, and waterway networks. Whether it's refining air travel, bolstering railway efficiency, optimizing road networks, or enhancing waterway logistics, our solutions are tailored to unlock seamless journeys and maximize operational efficiency in every mode of transportation
            </p>
    </div>
    <div className='hero-btns-sol' style={{marginTop:'2%'}}>
        <Button className='btns-sol' buttonStyle='btn--outline' buttonSize='btn--large'>
          <p>Contact Us</p>
        </Button>
      </div>
</div>
</div>
  );
}

export default OurSolutionsD