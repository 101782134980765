import React from 'react'
import './TeamC.css';
import '../App.css';
import imgHome from '../Group 343.png';
function TeamC() {
  return (
    <div className='teamC'>
        <h1>Advantages of Dedicated Team Model</h1>
    <img src={imgHome} alt="Kavya AI" /> 
    
</div>
  )
}

export default TeamC