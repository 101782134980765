import React from 'react'
import '../App.css';
import './HomeG.css';
import imgHome from '../embotech.jpeg';
import imgHome2 from '../embotech-logo.png';
function HomeG() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
<div className='homeG-container'>
<div className="imageG-overlay" ></div>
    <img src={imgHome} alt="Home Image"  />
    <div className='text-wrapperG' >
         <h1 >Partners</h1>
         <div className='division-line' ></div>
        <img src={imgHome2} alt="Home Image"  />
        <div className='bottom-line' ></div>

 <div className="content-wrapperG" >
       
        <h1 >"Their professionalism and transparency helped us stay informed and involved in every stage of development. Communication was seamless throughout the entire process. They provided regular updates, addressed our questions and concerns promptly."</h1>
       
        </div>
    </div>

</div>
</div>

  );
}

export default HomeG