import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';
import logo from '../Connc logo white.svg'; // Import your SVG logo

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [servicesDropdown, setServicesDropdown] = useState(false);
    const [ourServicesDropdown, setOurServicesDropdown] = useState(false);

    const menuRef = useRef();

    const handleClick = (menuItem) => {
        if (menuItem === 'services') {
            setServicesDropdown(!servicesDropdown); // Toggle services dropdown
        } else {
            setServicesDropdown(false); // Close services dropdown if clicking on other items
            setClick(false); // Close the mobile menu for other items
        }
    };

    const closeMobileMenu = () => setClick(false);

    const closeDropdownMenu = () => {
        setServicesDropdown(false);
        closeMobileMenu();
    };

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setServicesDropdown(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 960) {
                setClick(false); // Close the mobile menu on resize if the screen is wider than 960px
            }
            showButton();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img src={logo} alt="Connect Dynamics Logo" style={{ height: '40px', marginRight: '10px' }} />
                        <p style={{ color: 'white', marginLeft: '2%' }}>CONNECT</p>
                        <p style={{ color: '#e34b4b', paddingLeft: '2%',  fontWeight: 'bold' }}>DYNAMICS</p>
                    </Link>
                    <div className='menu-icon' onClick={() => setClick(!click)}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/About' className='nav-links' onClick={() => handleClick()}>
                                About Us
                            </Link>
                        </li>
                        <li className='nav-item' ref={menuRef}>
                            <div className='nav-links' onClick={() => handleClick('services')}>
                                Our Services
                            </div>
                            <ul className={servicesDropdown ? 'dropdown-menu active' : 'dropdown-menu'}>
                                <li className='dropdown-item'>
                                    <Link to='/OurSolutions' className='nav-links' onClick={closeDropdownMenu}>
                                        AI and Machine Learning
                                    </Link>
                                </li>
                                <li className='dropdown-item'>
                                    <Link to='/Relay' className='nav-links' onClick={closeDropdownMenu}>
                                        Fr8relay
                                    </Link>
                                </li>
                                <li className='dropdown-item'>
                                    <Link to='/Team' className='nav-links' onClick={closeDropdownMenu}>
                                        Dedicated Team
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className='nav-item'>
                            <Link to='/Govt' className='nav-links' onClick={() => handleClick()}>
                                Government
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <div className='nav-links'>
                                <Button className='nav-btns' buttonStyle='btn--outline' buttonSize='btn--small'>
                                    Contact Us
                                </Button>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
