import React from 'react'
import '../App.css';
import './GovtC.css';
import { Button } from './Button';
import imgHome from '../Team.jpg';

function GovtC() {
  return (
    <div style={{backgroundColor:'#555555'}}>
    <div className='Govtc-container'>
    <div className='text-wrapper-govt-c'> {/* govt */}
        <img src={imgHome} alt="Home Image" />
        <h1>About Us</h1>
        <p style={{ color: 'white', marginTop:'2%' }}>
        Our company holds several crucial registrations that are essential for government and business transactions. We have a CAGE Code, which uniquely identifies our company to government and defense agencies, enabling streamlined procurement and contracting processes. We are also registered in the SAM, the official U.S. government database that verifies and manages vendor information to ensure compliance with federal requirements. Additionally, we possess a UEID number, a modern identifier provided by SAM for federal purposes.
        </p>
    </div>
    <div className='hero-btns-govt' style={{marginTop:'0%'}}>
        <Button className='btns-govt' buttonStyle='btn--outline' buttonSize='btn--large'>
          Contact Us
        </Button>
    </div>
</div>
</div>

  );
}

export default GovtC