import React from 'react';
import '../App.css';
import './GovtB.css';
import { Button } from './Button';
import certImg from '../Certification_Badge_2024.png'

function GovtB() {
  return (
    <div style={{backgroundColor:'#555555'}}>
    <div className='GovtB-container'>
      <div className='Govtcontent-wrapperB'>
        <div className='GovtB-section'>
          <img src={certImg} />
         
        </div>
        <div className='GovtB-section'>
          <h3 className='GovtB-subtitle'>FEDERAL, STATE AND LOCAL</h3>
          <div className='GovtB-learn-more'>
            <p>Learn more <i className='fas fa-arrow-right'></i></p>
          </div>
        </div>
        <div className='GovtB-section'>
          <p style={{color: 'white', marginBottom:'2%'}}>UEI: FE5TQ4PWNKQ3</p>
          <p style={{color: 'white', marginBottom:'2%'}}>CAGE: 8PDM3</p>
          <p style={{color: 'white', marginBottom:'2%'}}>NAICS:</p>
          <p style={{color: 'white', marginBottom:'2%'}}>513210, 541614, 541512, 541715, 518210</p>
         
        </div>
      </div>
    </div>
    </div>
  );
}

export default GovtB;
