import React, {useEffect} from 'react'
import HeroSectionSol from '../HeroSectionSol';
import FooterB from '../FooterB';
import OurSolutionsB from '../OurSolutionsB';
import OurSolutionsD from '../OurSolutionsD';
import OurSolutionsE from '../OurSolutionsE';
import OurSolutionsF from '../OurSolutionsF';
import AboutUsC from '../AboutUsC';
function OurSolutions() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
<HeroSectionSol />
<OurSolutionsB />
<OurSolutionsD />
<OurSolutionsE />
<OurSolutionsF />
<AboutUsC />
<FooterB />
   </>
  );
}

export default OurSolutions