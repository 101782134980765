import React, { useEffect } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Card from '../Card';
import Homec from '../Homec';
import HomeD from '../HomeD';
import FooterB from '../FooterB';
import HomeE from '../HomeE';
import HomeF from '../HomeF';
import HomeG from '../HomeG';


function Home() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
      }, []);
    return (
        <>
        <HeroSection />
        <Homec />
        <HomeD />
        <HomeE />
        <HomeF />
        <HomeG />
        <FooterB />
    
        </>
    );
}

export default Home;