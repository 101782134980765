import React from 'react'
import '../App.css';
import './OurSolutionsE.css';
import imgHome from '../ml-sol-j.jpeg';
import imgHome2 from './route2.jpg';
import imgHome3 from '../datascience.jpeg';
import imgHome4 from '../travel-delay2.jpeg';
import imgHome5 from '../supply-chain-2.jpeg';

function OurSolutionsE() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='OurSolutionsE-container'>
    <div className='text-wrapper-sol-e'> {/* sol */}
        <h1>Our Machine Learning and AI Services</h1>
        <div class="image-grid">
        <div class="image-grid-item">
    <div class="card">
        <img src={imgHome3} alt="Image 1" class="card-img" />
        <div class="card-description">
        <p style={{ color: '#222222' }}>sdfghaohfiah</p>
            <h3>Data Science</h3>
             {/* <p3 style={{ color: '#E34B4B', marginLeft: '70%' ,marginTop:'2%'}}> learn more <i class="fas fa-arrow-right"></i> </p3> */  }
        </div>
    </div>
    
</div>
<div class="image-grid-item">
    <div class="card">
        <img src={imgHome} alt="Image 1" class="card-img" />
        <div class="card-description">
         <p style={{ color: '#222222' }}>sdfghaohfiah</p>
            <h3>Machine Learning</h3> 
           {/* <p3 style={{ color: '#E34B4B', marginLeft: '70%' ,marginTop:'2%'}}> learn more <i class="fas fa-arrow-right"></i> </p3> */  }
            
            
        </div>
    </div>
</div>

        
    </div>
    <div class="image-grid">
        <div class="image-grid-item">
    <div class="card">
        <img src={imgHome4} alt="Image 1" class="card-img" />
        <div class="card-description">
        <p style={{ color: '#222222' }}>sdfghaohfiah</p>
            <h3>Travel Delay Prediction</h3>
             {/* <p3 style={{ color: '#E34B4B', marginLeft: '70%' ,marginTop:'2%'}}> learn more <i class="fas fa-arrow-right"></i> </p3> */  }  
        </div>
    </div>
    
</div>
<div class="image-grid-item">
    <div class="card">
        <img src={imgHome5} alt="Image 1" class="card-img" />
        <div class="card-description">
         <p style={{ color: '#222222' }}>sdfghaohfiah</p>
            <h3>Supply Chain Optimization</h3> 
            {/* <p3 style={{ color: '#E34B4B', marginLeft: '70%' ,marginTop:'2%'}}> learn more <i class="fas fa-arrow-right"></i> </p3> */  }
            
            
        </div>
    </div>
</div>

        
    </div>
       
    </div>
</div>
</div>
  );
}

export default OurSolutionsE;