import React from 'react'
import '../App.css';
import './RelayE.css';
import imgHome from './reduce.jpeg';
import imgHome2 from '../attract.jpeg';
import imgHome3 from '../fr8relay-home.jpeg';

import { Button } from './Button';
function RelayE() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='Relaye-container'>
  <div className='text-wrapper-relaye'> {/* Updated class name */}
    <div className='img-div' > 
      <img src={imgHome} alt="Home Image" style={{ width: '100%' ,marginBottom: '5%',height:'auto'}} />
      <p style={{ color: 'white', fontSize: '20px'}}>REDUCE EMPTY MILES</p> 
    </div>
    <div className='img-div' > 
      <img src={imgHome2} alt="Home Image" style={{ width: '100%' ,marginBottom: '5%',height:'auto'}} />
      <p style={{ color: 'white', fontSize: '20px' }}>ATTRACT & RETAIN DRIVERS</p> 
    </div>
    <div className='img-div' > 
      <img src={imgHome3} alt="Home Image" style={{ width: '100%' ,marginBottom: '5%',height:'auto'}} />
      <p style={{ color: 'white', fontSize: '20px'}}>CUT SHIPPING TIMES</p> 
    </div>
  </div>
</div>
</div>
  
  );
}

export default RelayE