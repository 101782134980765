import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './component/Navbar';
import Home from './component/pages/Home';
import Card from './component/pages/Card';
import Homec from './component/pages/Homec';
import HomeD from './component/pages/HomeD';
import About from './component/pages/About';
import OurSolutions from './component/pages/OurSolutions';
import Relay from './component/pages/Relay';
import Team from './component/pages/Team';
import Govt from './component/pages/Govt';


function App() {
  return (
  <>
  <Router basename={process.env.PUBLIC_URL}>  
       <Navbar />
          <Routes>
            <Route path='/' exact element ={<Home/>} />
            <Route path='/About' exact element ={<About/>} />
            <Route path='/OurSolutions' exact element = {<OurSolutions />}/>
            <Route path='/Relay' exact element = {<Relay />}/>
            <Route path='/Team' exact element = {<Team />}/>
            <Route path='/Govt' exact element = {<Govt />} />
            
  

            </Routes> 
  </Router>


 </>
  );
}

export default App; 
 