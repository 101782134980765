import React, { useRef, useEffect } from 'react';
import './GrantAbout.css';
import GrantCardItem2 from './GrantCardItem2';
import imgHome from '../News1.png';
import imgHome2 from '../News2.png';
import imgHome3 from '../News3.png';
import imgHome4 from '../News4-4.png';
import imgHome5 from '../News5.png';
import imgHome6 from '../News6-2.png';
import imgHome7 from '../New7.png';
import imgHome8 from '../News8.png';


function GrantAbout() {
    const grantCardsRef = useRef(null);
    const animationFrameId = useRef(null);

    useEffect(() => {
        const container = grantCardsRef.current;
        const clonedItems = container.innerHTML;
        container.innerHTML += clonedItems; 

        const animateScroll = () => {
            if (container) {
                container.scrollLeft += 1; 
                if (container.scrollLeft >= container.scrollWidth -container.clientWidth) {
                    container.scrollLeft = 0;
                }
            }
            animationFrameId.current = requestAnimationFrame(animateScroll);
        };

        const handleMouseEnter = () => {
            cancelAnimationFrame(animationFrameId.current);
        };

        const handleMouseLeave = () => {
            animationFrameId.current = requestAnimationFrame(animateScroll);
        };

        if (container) {
            animationFrameId.current = requestAnimationFrame(animateScroll);
            container.addEventListener('mouseenter', handleMouseEnter);
            container.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (container) {
                cancelAnimationFrame(animationFrameId.current);
                container.removeEventListener('mouseenter', handleMouseEnter);
                container.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);

    return (
        <div className='grantcards2'>
            <h1 className='grantcards2__title'>News and Events</h1>
            <div className='grantcards2__container' ref={grantCardsRef}>
                <div className='grantcards2__items'>
                    <GrantCardItem2
                        imageSrc={imgHome}
                        text='FR8relay pitches logistics tech to DOD officials'
                        additionalText='Bentonvilles FR8relay presented logistics solutions at the 2023 Operational Energy and Logistics Summit, optimizing freight movement for the Department of Defense.'
                        additionalText2='News - Sep 21, 2023'
                        additionalText4='Learn More'
                        link='https://talkbusiness.net/2023/09/bentonville-startup-fr8relay-pitches-logistics-tech-to-dod-officials/'
                    />
                    <GrantCardItem2
                        imageSrc={imgHome2}
                        text='Transportation out front in economic rebound'
                        additionalText='Central Arkansas saw sustained economic improvement in 2022, signaling a resurgence in local business growth and employment, breaking free from the pandemics grip on the economy.'
                        additionalText2='News - Mar 5, 2023'
                        additionalText4='Learn More'
                        link='https://www.arkansasonline.com/news/2023/mar/05/wholesale-trade-transportation-out-front-in-areas/'
                    />
                    <GrantCardItem2
                        imageSrc={imgHome3}
                        text='Tyson focused on upcycling at Demo Day'
                        additionalText='Upcycling is key to our sustainable future, providing economic gains through cost reduction and environmental benefits by minimizing landfill waste.'
                        additionalText2='News - Mar 2, 2023'
                        additionalText4='Learn More'
                        link='https://www.meatpoultry.com/articles/28098-tyson-focused-on-upcycling-at-upcoming-demo-day'
                    />
                    <GrantCardItem2
                        imageSrc={imgHome4}
                        text='FR8relay’s NSF Award Marks 4th SBIR Win '
                        additionalText='FR8relay has received a nearly $256,000 Small Business Innovation Research Phase I award from the National Science Foundation.'
                        additionalText2='News - Mar 1, 2023'
                        additionalText4='Learn More'
                        link='https://asbtdc.org/fr8relay-wins-nsf-award-to-test-machine-learning-in-relay-trucking-models/'
                    />
                    <GrantCardItem2
                        imageSrc={imgHome5}
                        text='Fr8Relay excels in the Transportation Industry.'
                        additionalText='The relay model, that is attached to FR8relay’s name, is one that allows trucking companies to move freight across the country — while making a way for truck drivers to stay within a three- or four-hour radius'
                        additionalText2='News - Nov 14, 2022'
                        additionalText4='Learn More'
                        link='https://armoneyandpolitics.com/driving-innovation-fr8relay-accelerates-in-the-transportation-industry/'
                    />
                    <GrantCardItem2
                        imageSrc={imgHome6}
                        text='NWA Entrepeneur Joins Forbes Business Council'
                        additionalText='Thakur was recently invited to join the Forbes Business Council, the foremost growth and networking organization for successful business owners and leaders worldwide.'
                        additionalText2='News - Oct 17, 2022'
                        additionalText4='Learn More'
                        link='https://argotsoul.com/2022/10/arkansas-business-owner-joins-forbes-business-council/'
                    />
                    <GrantCardItem2
                        imageSrc={imgHome7}
                        text='Federal funding tops $1 million for FR8Relay'
                        additionalText='The grant project revolves around FR8Relays technology, optimizing freight movement and ensuring daily driver returns home.'
                        additionalText2='News - Oct 13, 2022'
                        additionalText4='Learn More'
                        link='https://talkbusiness.net/2022/10/federal-funding-tops-1-million-for-fr8relay/'
                    />
                    <GrantCardItem2
                        imageSrc={imgHome8}
                        text='Tyson Ventures picks 6 startups at inaugural Demo Day'
                        additionalText='Bentonville-based logistics startup FR8relay was selected as one of the six companies to receive mentorship and other partnership opportunities with Springdale-based meat giant Tyson Foods Inc'
                        additionalText2='News - Aug 5, 2022'
                        additionalText4='Learn More'
                        link='https://www.5newsonline.com/article/news/local/tyson-ventures-picks-6-startups-inaugural-demo-day-bentonville-arkansas-fr8relay/527-c407dabe-d6ca-44d0-9c0c-e1be2f82562c'
                    />
                </div>
            </div>
        </div>
    );
}

export default GrantAbout;
