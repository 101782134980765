import React, { useEffect } from 'react';
import HeroSectionAbout from '../HeroSectionAbout';
import AboutUsB from '../AboutUsB';
import AboutUsC from '../AboutUsC';
import CardAbout from '../CardAbout';
import GrantAbout from '../GrantAbout';
import FooterB from '../FooterB';


function About() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
   <>
   <HeroSectionAbout />
   <AboutUsB />
   <GrantAbout />
   <CardAbout />
   
   <AboutUsC />
   <FooterB />

  
   </>
  );
}

export default About;