import React, {useEffect} from 'react'
import RelayHero from '../RelayHero';
import RelayB from '../RelayB';
import FooterB from '../FooterB';
import RelayC from '../RelayC';
import AboutUsC from '../AboutUsC';
import RelayE from '../RelayE';
import OurSolutionsF from '../OurSolutionsF';


function Relay() {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return (
   <>
<RelayHero />
<RelayB />
<RelayC />
<RelayE />
<OurSolutionsF />
<AboutUsC />
<FooterB />
   </>
  );
}

export default Relay;