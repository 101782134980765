import React from 'react'
import { Link } from 'react-router-dom';

function TeamCardsItem(props) {
  return (
    <>
      <div className='teams-cards2__item'> {/* Updated class name */}
        <figure className='teams-cards2__item__pic-wrap'> {/* Updated class name */}
          <img src={props.imageSrc} alt='' className='teams-cards2__item__img' /> {/* Updated class name */}
        
          <span className='teams-cards2__item__text'>{props.text}</span> {/* Updated class name */}
          <br />
          <br />
          <br />
          <br />
          <br />
        
        
          <span className='teams-cards3__item__text'>{props.additionalText}</span> {/* Updated class name */}
          <br />
          <br />
          
        </figure>
        <div className='teams-cards2__item__info'> {/* Updated class name */}
          {props.link && (
            <Link to={props.link} className='teams-cards2__item__link'> {/* Updated class name */}
              <h5 className='teams-cards2__item__text'>{props.text}</h5> {/* Updated class name */}
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

export default TeamCardsItem;