import React, { useRef, useEffect, useState } from 'react';
import './CardAbout.css';
import CardItem2 from './CardItem2';
import imgHome2 from '../1 Dev of Algo.png';
import imgHome3 from '../2 Est of State.png';
import imgHome4 from '../3 Truck Fleet.png';
import imgHome5 from '../fr8relay.jpeg';

function CardAbout() {
    const [isHovered, setIsHovered] = useState(false);
    const cardsRef = useRef(null);
    const scrollIntervalRef = useRef(null);

    const scrollSpeed = 2; // pixels per interval

    const startScrolling = () => {
        scrollIntervalRef.current = setInterval(() => {
            if (cardsRef.current && !isHovered) {
                const currentScrollLeft = cardsRef.current.scrollLeft;
                const maxScrollLeft = cardsRef.current.scrollWidth / 2;

                if (currentScrollLeft >= maxScrollLeft) {
                    cardsRef.current.scrollLeft = 0;
                } else {
                    cardsRef.current.scrollTo({
                        left: currentScrollLeft + scrollSpeed,
                        behavior: 'smooth'
                    });
                }
            }
        }, 50);
    };

    const stopScrolling = () => {
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current);
            scrollIntervalRef.current = null;
        }
    };

    useEffect(() => {
        startScrolling();
        return stopScrolling;
    }, [isHovered]);

    const cardData = [
        {
            imageSrc: imgHome2,
            text: 'Development of Algorithms to Convert Large Streams of Truck GPS Data into Truck Trips',
            additionalText: 'Publication date: 2015/1',
            additionalText2: 'Read More',
            link: 'https://journals.sagepub.com/doi/abs/10.3141/2529-07?casa_token=OTx6DKNGOJsAAAAA:67LMtqa9mDOETcnKsCw0fYAyOPdq4sFkzL9lsrdpeUmCA2RgvfQbkCztLbJX7vf0TBygb1UtPDMZ_Zw'
        },
        {
            imageSrc: imgHome3,
            text: 'Estimation of statewide origin–destination truck flows from large streams of GPS data: Application for Florida statewide model',
            additionalText: 'Publication date: 2019/04',
            additionalText2: 'Read More',
            link: 'https://journals.sagepub.com/doi/abs/10.3141/2494-10?casa_token=UKtOv1Z4x9EAAAAA:mS0w0Nj4a5ACzDaiLklhrCB6mo7yt2tb12tF6RFaAUZ0DmMfftdt5UwK66BCfJL22KKtWmsz5FmM_m0'
        },
        {
            imageSrc: imgHome4,
            text: 'Using truck fleet data in combination with other data sources for freight modeling and planning.',
            additionalText: 'Publication date: 2014/07',
            additionalText2: 'Read More',
            link: 'https://rosap.ntl.bts.gov/view/dot/27807'
        },
        {
            imageSrc: imgHome5,
            text: 'Relay Model for Full Truckload Freight Transportation',
            additionalText: 'Publication date: 2023/01',
            additionalText2: 'Read More',
            link: 'https://www.proquest.com/openview/b69eb2a2cadb0eba3e45af804f51e1e0/1?pq-origsite=gscholar&cbl=51908'
        }
    ];

    return (
        <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
        <div 
            className='cards2' 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
        >
            <h2 className='cards2__title'>Paper Publications</h2>
            <div className='cards2__container' ref={cardsRef}>
                <div className='cards2__items'>
                    {cardData.map((card, index) => (
                        <CardItem2
                            key={index}
                            imageSrc={card.imageSrc}
                            text={card.text}
                            additionalText={card.additionalText}
                            additionalText2={card.additionalText2}
                            link={card.link}
                        />
                    ))}
                    {cardData.map((card, index) => (
                        <CardItem2
                            key={`clone-${index}`}
                            imageSrc={card.imageSrc}
                            text={card.text}
                            additionalText={card.additionalText}
                            additionalText2={card.additionalText2}
                            link={card.link}
                        />
                    ))}
                </div>
            </div>
        </div>
        </div>
    );
}

export default CardAbout;
