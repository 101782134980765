import React from 'react';
import './GrantAbout.css';

function GrantCardItem2(props) {
  return (
    <li className='grantcards2__item'>
      <a href={props.link} className='grantcards2__item__link' target="_blank" rel="noopener noreferrer" title={props.link}>
        <figure className='grantcards2__item__pic-wrap'>
          <img src={props.imageSrc} alt='' className='grantcards2__item__img' />
          <span className='grantcards2__item__text'>{props.text}</span>
          <br />
          <br />
          <br />
          <br/>
          <br />
          <br />
          <span className='grantcards3__item__text'>{props.additionalText}</span>
          <br />
          <br />
          <span className='grantcards4__item__text'>{props.additionalText2}</span>
          <br />
          <span className='grantcards5__item__text'>{props.additionalText3}</span>
          <span className='grantcards6__item__text'>{props.additionalText4} <i className='fas fa-chevron-right'></i></span>
        </figure>
      </a>
      <div className='grantcards2__item__info'>
        {/* Optionally, add a link if needed */}
        {props.link && (
          <a href={props.link} className='grantcards2__item__link' target="_blank" rel="noopener noreferrer">
            <h5 className='grantcards2__item__text'></h5>
          </a>
        )}
      </div>
    </li>
  );
}

export default GrantCardItem2;
