import React , { useState, useEffect }from 'react';
import './GovtHero.css';
import { Button } from './Button';
import '../App.css';
import imgHome from '../Govt-hero.jpeg';

function GovtHero() {
  return (
    <div style={{backgroundColor:'#555555'}}>
    <div className='govt hero-container'>
    
    <div className='govt hero-container2'>
 
    <h1>GOVERNMENT</h1>
   
   
   
</div>
<div className='govt hero-btnss'style={{zIndex:'1'}} >
        <Button className='btns-sol' buttonStyle='btn--outline' buttonSize='btn--large'>
          Contact Us
        </Button>
</div>
</div>
</div>

  );
}

export default GovtHero;