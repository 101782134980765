import React from 'react'
import '../App.css';
import './RelayB.css';
import imgHome from '../relay-b-j3.png';
import imgHome2 from '../fr8relay-home.jpeg';
import { Button } from './Button';

function RelayB() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='RelayC-container'>
            <div className='image-wrapper'>
                <img src={imgHome} alt="Home Image" />
                <div className='text-overlay'>
                    <h1>Revolutionizing Freight</h1>
                    <p>
                    FR8relay is tackling the biggest concerns in the trucking industry. Over 70% of freight in the United States moves by truck, but core inefficiencies in the way long-haul trucking operates threaten the industry’s sustainability and profitability.
                    </p>
                    <div className='relayb-btns'>
                    <Button className='' buttonStyle='btn--outline' buttonSize='btn--large'>
                        Contact Us
                    </Button>
                    </div>
                </div>
            </div>
        </div>
        </div>
  );
}

export default RelayB;