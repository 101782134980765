import React from 'react'
import '../App.css';
import './AboutUsC.css'
import { Button } from './Button';

function AboutUsC() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='aboutusc-container'>
    <h1>Get in touch </h1>
    <p>Book a Demo call now to know how we can help to create seamless and effective partnership experience to achieve your software development and IT objectives faster. We look forward to partnering with you and driving your success.</p>

    <div className='aboutusc-btn' >
            <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large' >
                Contact Us
            </Button>

        </div>
  </div>
  </div>
  );
}

export default AboutUsC