import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import './AboutUsB.css';
import imgHome from '../AayushT.jpg';
import imgHome2 from '../Deme2.JPG';

function AboutUsB() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const popupRef = useRef(null);

  const handleImageClick = (imageInfo) => {
    setPopupContent(imageInfo);
    setShowPopup(true);
    document.body.classList.add('block-scroll');
  };

  const closePopup = () => {
    setShowPopup(false);
    document.body.classList.remove('block-scroll');
  };

  useEffect(() => {
    const handleScroll = (e) => {
      if (popupRef.current) {
        setPopupPosition((prevPosition) => ({
          x: prevPosition.x,
          y: prevPosition.y - e.deltaY,
        }));
      }
    };

    if (showPopup) {
      window.addEventListener('wheel', handleScroll);
    }

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [showPopup]);

  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='aboutusb-container' >
      <div className='border'>
        <h1 style={{ textAlign: 'center' }}>Our Leadership</h1>

        <div className='text-wrapper2'>
          <img
            src={imgHome}
            alt="Home Image"

            onClick={() => handleImageClick({
              image: imgHome,
              text: [
                {
                  text: 'Aayush Thakur',
                  fontSize: '24px',
                  color: '#E34b4b',
                  textAlign: 'center',
                  linkedin: 'https://www.linkedin.com/in/aayushthakur/' 
                },
                {
                  text: 'CEO',
                  fontSize: '14px',
                  color: '#E34b4b',
                  textAlign: 'center',
                  marginTop:'0%',
                  
                },
                {
                  text: 'Our Founder and CEO Aayush Thakur was recently accepted into the Forbes Business Council, the foremost growth and networking organization for successful business Paper Publication owners and leaders worldwide. He was selected by a review committee based on the depth and diversity of his experience. Acceptance to the council is by invitation only, and criteria include a track record of successfully impacting business growth metrics, as well as personal and professional achievements and honors.',
                  fontSize: '14px',
                  color: 'white'
          
                },
              ]
            })}
          />
   

          <img
            src={imgHome2}
            alt="Home Image"
         
            onClick={() => handleImageClick({
              image: imgHome2,
              text: [
                {
                  text: 'Deme Yuan',
                  fontSize: '22px',
                  color: '#E34b4b',
                  textAlign: 'center',
                  linkedin: 'https://www.linkedin.com/in/demeyuan/' // Example LinkedIn profile URL
                },
                {
                  text: 'COO',
                  fontSize: '14px',
                  color: '#E34b4b',
                  textAlign: 'center',
                  marginTop:'0%'
                },
                {
                  text: 'Deme Yuan (COO and Cofounder) brings focus to both strategic and operational planning and execution at Connect Dynamics, Inc. She has over a decade’s experience building teams and organizational processes, developing collaborative partnerships and running pilot projects. Deme has a lens for equity and inclusiveness, and is driven by endeavors with social impact potential. She studied Leadership for Organizational Development while attaining her Master of Social Work from the University of Denver, and majored in Sociology for her Bachelor of Arts from The Colorado College.',
                  fontSize: '14px',
                  color: 'white'
                },
              ]
            })}
          />
        </div>

        {showPopup && (
          <div
            className="popup-about"
            style={{
              overflowY: 'scroll',
              overflow: 'visible',
              top: `${popupPosition.y}px`,
              left: `${popupPosition.x}px`,
              position: 'fixed'
            }}
            ref={popupRef}
          >
            <div className="popup-inner-about" style={{ overflowY: 'scroll', overflow: 'visible' }}>
              <button className="close-btn" onClick={closePopup}> <i class="fa-solid fa-xmark"> </i></button>
              <img src={popupContent.image} alt="Popup Image" />
              {popupContent.text.map((line, index) => (
                <div key={index}>
                  <p style={{ fontSize: line.fontSize, color: line.color, textAlign: line.textAlign, marginTop: line.marginTop,paddingLeft:'30px',paddingRight:'30px',lineHeight:'1.5rem' }}>{line.text}</p>
                </div>
              ))}
              {/* LinkedIn logo outside the loop */}
              
              <a href={popupContent.text[0].linkedin} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin linkedin-icon" style={{ color: 'white', fontSize: '30px', float: 'right', marginTop: '30px',paddingRight:'30px',marginBottom:'3%'}} />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  );
}

export default AboutUsB;
