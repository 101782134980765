import React, { useEffect, useRef } from 'react';
import '../App.css';
import './RelayC.css';
import imgHome from '../our-vision.jpeg';
import semiImg from '../relay-semi.png'
import videoHome from '../relay-movie-org2.mov';
import { Button } from './Button';

function RelayC() {
  const videoRef = useRef(null);

 /* useEffect(() => {
    const video = videoRef.current;

    const handlePlay = () => {
      if (video.paused) {
        video.play().catch((error) => {
          console.error('Error attempting to play the video:', error);
        });
      }
    };

    const handleScroll = () => {
      const rect = video.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        handlePlay();
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); */

  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='RelayD-container'>
      <div className='text-wrapper-relayd'>
        <img src={semiImg} />
        <h1>Transforming Long Haul Trucking</h1>
        <p style={{ color: 'white', fontSize: '18px' }}>
          At Connect Dynamics, we understand the intricacies of transportation challenges. That's why our AI and ML solutions are meticulously designed to tackle the nuances of route optimization and travel delay prediction across air, railway, road, and waterway networks. Whether it's refining air travel, bolstering railway efficiency, optimizing road networks, or enhancing waterway logistics, our solutions are tailored to unlock seamless journeys and maximize operational efficiency in every mode of transportation.
        </p>
        <h1>Pioneering the Relay Model</h1>
        <p style={{ color: 'white', fontSize: '18px' }}>
          FR8relay’s novel technology uses multiple data inputs to dynamically create driver schedules along truck lanes with strategically distributed relay nodes. The relay system helps keep equipment and goods moving while allowing drivers to go home every day.
        </p>
        <h1>The Future of Freight</h1>
        <p style={{ color: 'white', fontSize: '18px' }}>
          Carriers could experience a twofold increase in asset utilization, a 50% reduction in shipping times, and significant decrease in cost of truck driver turnover. Carriers and shippers will be able to successfully deploy a relay trucking model and save millions of dollars annually.
        </p>
      </div>
    </div>
    </div>
  );
}

export default RelayC;
