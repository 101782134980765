import React from 'react'
import '../App.css';
import './OurSolutionsF.css';
import imgHome from '../case-study.jpeg';

function OurSolutionsF() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='OurSolutionsF-container'>
    <div className='text-wrapper-sol-f'> {/* sol */}
    <h1>Case Studies</h1>
        <div className='outer-sol-div' >
            <img src={imgHome} alt="Home Image" style={{ float: 'left' ,height: '75%'}}/>
            <div className = 'inner-sol-div' >
                <h1 className='custom-h1'>Freight Cost Optimization using Machine Learning</h1>
                <p className='custom-p'>Our ongoing project focuses on optimizing supply chains to reduce energy consumption anc environmental impact. Through innovative strategies such as pooling shipments and efficiently matchina capacitv. we ensure the continuous movement of cargo while enablina dailv drive
returns. This approach, still in proaress, aims to enhance efficiency and sustainability by minimizing fuel usage and emissions.</p>
                <p3 className='custom-p3'>Learn more <i class="fas fa-arrow-right"></i></p3>
            </div>
        </div>
    </div>
</div>
</div>

  );
}

export default OurSolutionsF;
