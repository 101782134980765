import React from 'react';

import { Button } from './Button';
import './HeroSection.css';
import './HeroSectionSol.css';
import imgHome from '../hero-section.jpeg';


function HeroSection() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='hero-container'>
   <div className='hero-background'>
        <h1>Introducing Connect Dynamics</h1>
        <h2>Where Innovation Meets</h2>
        <h1>Transporation Excellence</h1>
        <p>Welcome to Connect Dynamics, founded in 2018, where we harness the power of artificial intelligence 
        and machine learning to revolutionize the future of transportation. Our predictive solutions are designed 
        to minimize travel delays and optimize routes across all modes of transportation.</p>
        <div className='hero-btns-sol' style={{marginTop:'5%'}}>
        <Button className='btns-sol' buttonStyle='btn--outline' buttonSize='btn--large'>
          Contact Us
        </Button>
      </div>
    </div>
    </div>
    </div>
  );
}

export default HeroSection;