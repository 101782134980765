import React from 'react'
import './TeamB.css';
import { Button } from './Button';
import '../App.css';
import imgHome from '../Team-staffing.jpeg';
function TeamB() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='teamb-container' style={{backgroundColor:'#555555'}}  >
    <div className='text-wrapper-team-b' style={{backgroundColor:'#555555'}} >
        <h1 style={{backgroundColor:'#555555'}}>We Offer Dedicated Development teams</h1>
        <p>As a Dedicated Development Team provider we act as a strategic partner for our client's, helping them access skilled resources, manage the development process, and ensure the successful execution of software development or IT projects. Our goal is to create a seamless and efficient collaboration that delivers value to the client's business.</p>
        <img src={imgHome} alt="Home Image"  />
        

    </div>
</div>
</div>

  )
}

export default TeamB;