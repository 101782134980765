import React from 'react'
import '../App.css';
import './OurSolutionsB.css';
import imgHome from '../ML-hero3.png';
function OurSolutionsB() {
    return (
        <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
        <div className='OurSolutionsC-container'>
        <h1>Our Expertise</h1>
        <div className='text-wrapper-sol'> 
            <img src={imgHome} alt="Home Image" />
            <h1>Cutting Edge Technology</h1>
            <p style={{ color: 'white' }}>
            Empower your business with the latest in AI and ML technology. Our team of data scientists and AI specialists specializes in integrating cutting-edge solutions that drive efficiency, boost productivity, and unlock new possibilities by bring unparalleled expertise to every project.
            </p>
        </div>
    </div>
    </div>
  
  
      );
}

export default OurSolutionsB