import React from 'react'
import '../App.css';
import './HomeE.css';
import imgHome from './team3.jpg';

function HomeE() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='homeE-container'>
    <h1>Technical Staffing</h1>
    <div className='text-wrapperE' >
        <div className="content-wrapperE" >
            <h3>Looking Forward to expand your Team</h3>
            <p >
            "Technical Staffing" is the process of recruiting, hiring, and managing employees or contractors with specialized technical skills and expertise. Technical staffing involves sourcing candidates, evaluating their qualifications, conducting interviews, and ultimately selecting the most suitable candidates.
            </p>
            <div className="learn-moreE" >
                <p >Learn more <i className="fas fa-arrow-right"></i></p>
            </div>
        </div>
        <img src={imgHome} alt="Home Image"/>
    </div>
</div>
</div>


  );
}

export default HomeE