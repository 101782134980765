import React, {useEffect} from 'react';
import RelayHero from '../RelayHero';
import TeamHero from '../Team-hero';
import Card from '../Card';
import TeamB from '../TeamB';
import TeamC from '../TeamC';
import TeamCards from '../TeamCards';
import AboutUsC from '../AboutUsC';
import FooterB from '../FooterB';


function Team() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
   <TeamHero />
   <Card />
   <TeamB />
   <TeamC />
   <TeamCards />
   <AboutUsC />
   <FooterB />
    </>
  );
}

export default Team;