import React, { useState } from 'react';
import './Button.css';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const STYLES = ['btn--primary', 'btn--outline', 'btn--custom', 'btn--outline2'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({ children, type, onClick, buttonStyle, buttonSize, popupContent }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        notifications: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phone: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Send form data to backend
        try {
            const response = await fetch('/api/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                // Reset form on successful submission
                setFormData({
                    firstName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    notifications: false
                });
                alert('Subscription successful!');
            } else {
                alert('Subscription failed. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Subscription failed. Please try again later.');
        }
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <>
            <button
                className={`btn ${checkButtonStyle} ${checkButtonSize} add-button`}
                onClick={(e) => {
                    togglePopup();
                    onClick && onClick(e);
                }}
                type={type}
            >
                {children}
            </button>
            {showPopup && (
                <div className="popup add-button">
                    <span className="popup-close" onClick={togglePopup}>  <i class="fa-solid fa-xmark"> </i></span>
                    <div className="popup-content add-button">
                        <h1>Contact Us</h1>
                        <form onSubmit={handleSubmit} style={{marginLeft: '-7%',marginRight:'-20%'}}>
                            <div className="inline-inputs">
                                <input
                                    className='footer-input'
                                    name='firstName'
                                    type='text'
                                    placeholder='First Name'
                                    value={formData.firstName}
                                    onChange={handleChange}

                                />
                                <input
                                    className='footer-input'
                                    name='lastName'
                                    type='text'
                                    placeholder='Last Name'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="inline-inputsb">
                                <PhoneInput
                              
                                    country={'us'}
                                    value={formData.phone}
                                    onChange={handlePhoneChange}
                                    inputStyle={{ backgroundColor: '#4e4e4e', border: 'none', padding: '0', width: '21.5rem',borderRadius:'1%',height:'3rem',marginLeft:'42px',color:'lightgray' }}
                                    buttonStyle={{ backgroundColor: '#4e4e4e', borderColor:'white' }}
                                />
                               
                            </div>
                            <div className="inline-inputs">
                            <input
                                    className='footer-input'
                                    name='email'
                                    type='email'
                                    placeholder='Your Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    style={{marginTop:'3%',width:'24.4rem',marginLeft:'1%'}}
                                   
                                />
                                </div>
                            <div className="checkbox-container">
                                <input
                                    type="checkbox"
                                    name="notifications"
                                    checked={formData.notifications}
                                    onChange={handleChange}
                                />
                                
                                <label>Receive notifications on Email</label>
                           
                            </div>
              <div className='submit-btn' style={{marginRight:'10%',marginTop:'2%'}}>
                       <Button buttonStyle='btn--outline' buttonSize='btn--medium' type="submit"  style={{marginRight:'200px'}}>Submit</Button>
                        </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};
