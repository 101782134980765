import React from 'react'
import './RelayHero.css';
import '../App.css';
import { Button } from './Button';
import imgHome from '../relay-hero-j.jpeg';
import imgHome2 from '../relayB copy.jpeg';
import logo from '../fr8relay logo.png';


function RelayHero() {
  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
<div className='relay hero-container'>
  <div className='relay hero-background'>
   
 <img src={logo} /> 
{/* <h2 style={{color:'#e34b4b'}}>FR8Relay</h2> */ }
    <h2 style={{color:'#e34b4b'}} >Faster Freight.</h2>
    <h2>Happy Drivers.</h2>

    <div className='relay hero-btns'>
        <Button className='relay btns' buttonStyle='btn--outline' buttonSize='btn--large'>
            Contact Us
        </Button>
    </div>
    </div>
</div>
</div>
  );
}

export default RelayHero