import React from 'react';
import { Link } from 'react-router-dom';

function CardItem2(props) {
  return (
    <Link to={props.link} target="_blank" rel="noopener noreferrer" className="card-link">
      <div className='cards2__item'>
        <figure className='cards2__item__pic-wrap'>
          <img src={props.imageSrc} alt='' className='cards2__item__img' />
          <span className='cards2__item__text'>{props.text}</span>
          <br />
          <br />
          <br />
          <span className='cards3__item__text'>{props.additionalText}</span>
          <br />
          <br />
          <span className='cards4__item__text'>{props.additionalText2} <i className='fas fa-chevron-right'></i> </span>
        </figure>
      </div>
    </Link>
  );
}

export default CardItem2;
