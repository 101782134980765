import React from 'react';
import '../App.css';
import './Homec.css';
import imgHome from '../homec-img.jpg';
import HomecItem from './Homecitem';
import CardItem from './CardItem';


function Homec() {

    return (
      <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
      <div className='homec-container'>
      <h1>Empowering Success Through Innovative Software Solutions</h1>
      <div className='text-wrapperC'>
        <div  style={{ position: 'relative', display: 'flex', float: 'left' }}>
        <div className='img-background'></div>
          <img src={imgHome} alt="Home Image" style={{ float: 'left'}} />
        </div>
        <p style={{ color: 'white'}}>
          We are dedicated to fostering long-term partnerships by helping you empower success through innovative software solutions by offering
        </p>
        <ul className='custom-ul'>
        <li>Streamlined Processes </li>
          <li >Data-Driven Insights</li>
          <li >Enhanced Collaboration</li>
          <li >Customized Solutions</li>
          <li >Continuous Innovation</li>
         
        </ul>
      </div>
    </div>
    </div>
  );
  }
  
  export default Homec;