import React, { useEffect } from 'react';

import '../App.css';
import './HomeD.css';
import imgHome from './homec-2.png';
import HomecItem from './Homecitem';
import CardItem from './CardItem';


function HomeD() {

    return (
      <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
      <div className='homed-container'>
        <h3 > Product and Services </h3>
          <h1 > Software Solutions</h1>
<div className='text-wrapperD' >
<img src={imgHome} alt="Home Image"  />
<div className="content-wrapperD" >
        <h3 >Custom Transport Solutions</h3>
        <p>
        At Connect Dynamics, our product development revolves around harnessing the power of predictive solutions tailored for various modes of transportation. From road to air, water to railway, our AI-powered algorithms leverage real-time data and historical patterns to forecast congestion, delays, and optimize travel schedules. We’re committed to continuously advancing our technologies to ensure enhanced efficiency, reliability, and seamless journeys across all transportation sectors.
            </p>
        <div className="learn-moreD">
        <p style={{fontWeight:'bold'}}> Learn more <i  className="fas fa-arrow-right"></i></p>
    </div>
    </div>
    </div>
        </div>
</div>

    );
  }
  
  export default HomeD;