import React, { useRef } from 'react';
import './Card.css';
import CardItem from './CardItem';
import icon from '../angular-3.svg';
import icon2 from '../aws-2.svg';
import icon3 from '../mysql-logo.svg';
import icon4 from '../react-native-1.svg';
import icon5 from '../google-cloud-1.svg';
import icon6 from '../kafka.svg';
import icon7 from '../javascript-1.svg';
import icon8 from '../Google_Apps_Script.svg.png';
import icon10 from '../python-logo.png';

function Card() {
  const cardsRef = useRef(null);

  const scrollLeft = () => {
    if (cardsRef.current) {
      cardsRef.current.scrollLeft -= 50;
    }
  };

  const scrollRight = () => {
    if (cardsRef.current) {
      cardsRef.current.scrollLeft += 50;
    }
  };

  return (
    <div style={{backgroundColor:'#555555',zIndex:'-1'}}>
    <div className='cards'>
      <h2 className='cards__title'>Tech Stack We Use</h2>
      <div className='cards__container'>
        <div className='cards__items-container' ref={cardsRef}>
  
          <div className='cards__items'>
            <CardItem src={icon} />
            <CardItem src={icon2} />
            <CardItem src={icon3} />
            <CardItem src={icon4} />
            <CardItem src={icon5} />
            <CardItem src={icon7} />
            <CardItem src={icon10} />
          </div>
        
        </div>
      </div>
    </div>
    </div>
  );
}

export default Card;
